<template>
  <div class="login-wrap">
    <div class="login-content">
      <!-- 验证码密码登录 -->
      <div class="tab-mod" v-if="inputCode == 1">
        <div class="tab-bar">
          <div class="tab-term" :class="{cur: formData.loginType == 1}" @click="tabTerm(1)">验证码登录</div>
          <div class="tab-term" :class="{cur: formData.loginType == 2}" @click="tabTerm(2)" style="margin-left: 42px;">密码登录</div>
        </div>
        <div class="tab-cont">
          <div class="tab-item">
            <el-form :model="formData" :rules="rules" ref="ruleForm">
              
              <mobile :mobile.sync="formData.mobile" :propName="'mobile'" :leftIcon="true" :rightIcon="true" style="width: 380px; margin-bottom: 20px;"></mobile>

              <verification-code :verificationCode.sync="formData.smsCode" :propName="'smsCode'" :mobile="formData.mobile" @verify="verify" :leftIcon="true" v-if="formData.loginType == 1" style="width: 380px;"></verification-code>

              <div style="position: relative;">
                <pass-word :passWord.sync="formData.password" :propName="'password'" :leftIcon="true" :rightIcon="true" v-if="formData.loginType == 2" style="width: 380px;"></pass-word>
                <div style="position: absolute; bottom: -30px; right: 18px; cursor: pointer; font-size: 16px; color: #323232; line-height: 22px;" @click="JumpPage">忘记密码</div>
              </div>
              
              <el-form-item style="margin-bottom: 0; margin-top: 50px;">
                <el-button @click="determine('ruleForm')" type="primary" :loading="btnLoading" class="confirm">立即登录</el-button>
              </el-form-item>
            </el-form>
            <div class="text" style="width: 380px;">
              <div class="p">未注册的手机号验证通过后将自动注册</div>
              <div class="p">扫码表示已阅读并同意<span class="txt">《易橙用户协议》</span>和<span class="txt">《易橙隐私政策》</span></div>
            </div>
          </div>
        </div>
      </div>
      <!-- 扫码登录 -->
      <div class="scan-code" v-if="inputCode == 2">
        <div class="title">微信扫码登录</div>
        <div class="pic">
          <!-- <img src="" alt="" class="i"> -->
          <!-- 承载二维码容器 -->
          <div class="wx_dialog" id="login_container"></div>
          <!-- 刷新图标 -->
          <!-- <div class="wx_dialog_button_delete" @click="close_wchat_qrcode">
            <i class="el-icon-refresh" style="font-size: 26px"></i>
          </div> -->
        </div>
        <div class="text" style="width: 380px;">
          <div class="p" v-if="hasbindWechat !== 0">扫码表示已阅读并同意<span class="txt">《易橙用户协议》</span>和<span class="txt">《易橙隐私政策》</span></div>
          <div class="p" v-else>如退出流程，则代表注册失败，请谨慎操作</div>
        </div>
      </div>
      <!-- 切换图标 -->
      <div class="change-login-icon" v-if="hasbindWechat !== 0">
        <div class="txt"> {{ inputCode == 1 ? "微信扫码登录" : "验证码、密码登录" }} </div>
        <div class="pic code" @click="changeLoginMethod">
          <img v-if="inputCode == 1" src="@/assets/img/icon_004_01.png" alt="" class="i">
          <img v-if="inputCode == 2" src="@/assets/img/icon_004_02.png" alt="" class="i">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createUniqueString } from "@/utils"
import { removeToken } from '@/utils/auth'
import mobile from "@/components/Input/mobile";
import verificationCode from "@/components/Input/verificationCode";
import passWord from "@/components/Input/passWord";
import { mapGetters } from "vuex";
import { wechatLogin, bindWechat } from "@/api/user"; // 微信登录, 绑定微信
export default {
  components: {
    mobile, verificationCode, passWord,
  },
  computed: {
    ...mapGetters([
      'mobile',
      'hasbindWechat'
    ]),
  },
  data () {
    var checkMobile = (rule, value, callback) => {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
      if (!value) {
        callback(new Error('输入手机号'));
      } else if (!reg_tel.test(value)) {
        // setTimeout(() => {
          callback(new Error('输入正确的手机号'));
        // }, 500);
      } else {
        callback();
      }
    };
    var checkSmsCode = (rule, value, callback) => {
      var reg_tel = /[^0-9]/g;    // 4为以上数字
      if (!value) {
        callback(new Error('输入验证码'));
      } else if (reg_tel.test(value) || value.length < 4) {
        setTimeout(() => {
          callback(new Error('输入正确的验证码'));
        }, 500);
      } else {
        callback();
      }
    };
    var checkPassword = (rule, value, callback) => {
      var reg_tel = /^(\w){6,20}$/;    // 6-20个字母、数字、下划线正则
      if (!value) {
        callback(new Error('输入密码'));
      } else if (!reg_tel.test(value)) {
        setTimeout(() => {
          callback(new Error('只能输入6-20个字母、数字、下划线'));
        }, 500);
      } else {
        callback();
      }
    };
    
    return {
      formData: {
        loginType: '1', // 1 验证登录, 2 密码登录
        mobile: '', // 手机号
        smsCode: '',  // 验证码
        password: '', // 密码
      },
      rules: {
        mobile: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ],
        smsCode: [
          { required: true, validator: checkSmsCode, trigger: 'blur' }
        ],
        password: [
          { required: true, validator: checkPassword, trigger: 'blur' }
        ],
      },
      btnLoading: false,
      countDownTxt: '发送验证码',
      redirect: undefined,
      inputCode: 1, // 1 验证码密码, 2 扫码
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
        }
        // 扫码后拿到 code 进行登录
        if ( query.code ) {
          this.inputCode = 2;
          let p;
          if(query.mobile){ // 没绑定微信
            p = bindWechat({code: query.code, mobile: query.mobile});
          } else {
            p = wechatLogin({code: query.code})
          }
          p.then(res => {
            this.$store.dispatch('user/setLoginInfo', res);
            this.$router.push({ path: this.redirect || '/' });
          }).catch(err => {
            this.inputCode = 1;
            this.deleteHrefCodeState();
          });
        }
      },
      immediate: true
    }
  },
  created () {
    
  },
  mounted () {
    if(this.inputCode == 2){
      this.get_wx_qrcode(); // 获取微信的登录二维码
    }
  },
  methods: {
    // 立即登录
    determine(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          let params = {};
          if(this.formData.loginType == 1){ // 验证登录
            params = { loginType: this.formData.loginType, mobile: this.formData.mobile, smsCode: this.formData.smsCode };
          }
          if(this.formData.loginType == 2){ // 密码登录
            params = { loginType: this.formData.loginType, mobile: this.formData.mobile, password: this.formData.password };
          }
          this.$store.dispatch('user/mobileLogin', params).then(() => {
            // 没绑定微信
            if(this.hasbindWechat === 0){
              removeToken();
              this.inputCode = 2;
              this.get_wx_qrcode(); // 获取微信的登录二维码
            } else {
              this.$router.push({ path: this.redirect || '/' });
            }
            this.btnLoading = false;
          }).catch(err => {
            console.log(err);
            this.btnLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // tab-bar 切换
    tabTerm(code){
      this.$set(this.formData, 'loginType', code);
    },
    // 跳转重置密码页
    JumpPage(){
      this.$store.dispatch('app/LoginPage', 1);
    },
    // 切换登录方式
    changeLoginMethod(){
      // 重定向地址重定到当前页面，在路径获取code
      const hrefUrl = window.location.href;

      this.inputCode = this.inputCode == 1 ? 2 : 1;

      this.deleteHrefCodeState();

      if(this.inputCode == 2){
        this.get_wx_qrcode(); // 获取微信的登录二维码
      }
    },
    
     get_wx_qrcode(){
      let prod = 'https://www.ycmvp.com/'
      let test = 'https://test.ycmvp.com/'
      let dev = 'http://192.168.8.23:8080/'
      let txt = process.env.NODE_ENV;
      let hrefUrl;
      let redirect = this.redirect ? this.redirect : '/';
      let mobile = this.hasbindWechat === 0 ? this.formData.mobile : '';  // 没绑定微信记录手机号
      if(txt == 'development'){ // 本地
        hrefUrl = dev;
      } else if(txt == 'test'){ // 测试
        hrefUrl = test;
      } else if(txt == 'production'){ // 线上
        hrefUrl = prod;
      }
      this.$nextTick(() => {
      //从微信的开发文档不难看出回调域名需要经过encodeURIComponent处理
      new WxLogin({
        self_redirect: false, //值为true时, 直接在二维码的弹窗中进行页面跳转及其余操作,
        id: "login_container",  //需要承载二维码的容器id  
        appid: "wxfccff1fb6489ef45",
        scope: "snsapi_login",  //网页授权, 目前网页只有这一种方式, 静默授权
        redirect_uri: encodeURIComponent(`${hrefUrl}login?redirect=${redirect}&mobile=${mobile}`), //回调域名(地址前部分是项目域名，后部分loginSuccess是微信需要跳转的路径（vue中的路由名称）)
        state: createUniqueString(),
        // style: "white",
        href: `data:text/css;base64,LmltcG93ZXJCb3ggLnRpdGxlIHtkaXNwbGF5OiBub25lO30KLmltcG93ZXJCb3ggLmluZm8ge2Rpc3BsYXk6IG5vbmU7fQouaW1wb3dlckJveCAucXJjb2RlIHt3aWR0aDogMTAwJTsgbWFyZ2luLXRvcDogMDsgZGlzcGxheTogYmxvY2s7IGJvcmRlcjogbm9uZTt9`,  // base64 样式
      });
      // 修改 iframe 样式
      var iframe = document.getElementsByTagName('iframe')[0];
      iframe.setAttribute('width', '100%');
      iframe.setAttribute('height', '100%');
      })
    },
    // 清除导航栏 code 和 state 参数
    deleteHrefCodeState(){
      let newQuery = JSON.parse(JSON.stringify(this.$route.query)) // 先拷贝一个一模一样的对象
      delete newQuery.code; // 删除 code 参数
      delete newQuery.state;
      delete newQuery.mobile;
      this.$router.replace({ query: newQuery }) //再把新的替换了
    },
    // 刷新二维码
    close_wchat_qrcode(){
      this.get_wx_qrcode();
    },
    // 清空
    closeBtn(key){
      this.$set(this.formData, key, '');
    },
    verify() {
      // 手机号校验
      this.$refs['ruleForm'].validateField('mobile');
    },
  }
}
</script>

<style lang="scss" scoped>
@import "./login.scss";
.wx_dialog{ height: 100%; }
.wx_dialog_button_delete{
  display: inline-block;
}

// 二维码样式
.impowerBox .title {display: none;}
.impowerBox .info {display: none;}
.impowerBox .qrcode {width: 100%; margin-top: 0; display: block; border: none;}
</style>