<template>
  <div class="forgot-password">
    <div class="forgot-password-content">
      <div class="title">重置密码</div>

      <div class="form">
      <el-form :model="formData" :rules="rules" ref="ruleForm">
              
        <mobile :mobile.sync="formData.mobile" :propName="'mobile'" :leftIcon="true" :rightIcon="true" style="width: 380px; margin-bottom: 20px;"></mobile>

        <verification-code :verificationCode.sync="formData.smsCode" :propName="'smsCode'" :mobile="formData.mobile" @verify="verify" :leftIcon="true" style="width: 380px; margin-bottom: 20px;"></verification-code>

        <div style="position: relative;">
          <pass-word :passWord.sync="formData.password" :propName="'password'" :leftIcon="true" :rightIcon="true" style="width: 380px;" :placeholderTxt="'输入新密码'"></pass-word>
        </div>
        
        <el-form-item style="margin-bottom: 0; margin-top: 36px;">
          <el-button @click="determine('ruleForm')" type="primary" :loading="btnLoading" class="confirm">确认</el-button>
        </el-form-item>

        <el-form-item style="margin-bottom: 0; margin-top: 14px; text-align: center;">
          <el-button @click="JumpPage" type="text" class="return-back">返回</el-button>
        </el-form-item>
      </el-form>
      </div>

    </div>
  </div>
</template>

<script>
import mobile from "@/components/Input/mobile";
import verificationCode from "@/components/Input/verificationCode";
import passWord from "@/components/Input/passWord";
import { resetPassword, checkSmsCode } from "@/api/user";
export default {
  components: {
    mobile, verificationCode, passWord,
  },
  data () {
    var checkMobile = (rule, value, callback) => {
      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
      if (!value) {
        callback(new Error('输入手机号'));
      } else if (!reg_tel.test(value)) {
        // setTimeout(() => {
          callback(new Error('输入正确的手机号'));
        // }, 500);
      } else {
        callback();
      }
    };
    var checkSmsCode = (rule, value, callback) => {
      var reg_tel = /[^0-9]/g;    // 4为以上数字
      if (!value) {
        callback(new Error('输入验证码'));
      } else if (reg_tel.test(value) || value.length < 4) {
        setTimeout(() => {
          callback(new Error('输入正确的验证码'));
        }, 500);
      } else {
        callback();
      }
    };
    var checkPassword = (rule, value, callback) => {
      var reg_tel = /^(\w){6,20}$/;    // 6-20个字母、数字、下划线正则
      if (!value) {
        callback(new Error('输入新密码'));
      } else if (!reg_tel.test(value)) {
        setTimeout(() => {
          callback(new Error('只能输入6-20个字母、数字、下划线'));
        }, 500);
      } else {
        callback();
      }
    };
    
    return {
      formData: {
        mobile: '', // 手机号
        smsCode: '',  // 验证码
        password: '', // 密码
      },
      rules: {
        mobile: [
          { required: true, validator: checkMobile, trigger: 'blur' }
        ],
        smsCode: [
          { required: true, validator: checkSmsCode, trigger: 'blur' }
        ],
        password: [
          { required: true, validator: checkPassword, trigger: 'blur' }
        ],
      },
      btnLoading: false,
      countDownTxt: '发送验证码',
    }
  },
  methods: {
    // 确定
    determine(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          // 校验验证码
          checkSmsCode({mobile: this.formData.mobile, smsCode: this.formData.smsCode}).then(res => {
            if(res.code == 0){
              resetPassword({mobile: this.formData.mobile, newPassword: this.formData.password}).then(res => {
                this.$store.dispatch('app/LoginPage', 0);
                this.$message({
                  message: '操作成功',
                  type: 'success'
                })
                this.btnLoading = false;
              }).catch(err => {
                console.log(err);
                this.btnLoading = false;
              });
            }
          }).catch(err => {
            console.log(err);
            this.btnLoading = false;
          });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 跳转登录页
    JumpPage(){
      this.$store.dispatch('app/LoginPage', 0);
    },
    // 清空
    closeBtn(key){
      this.$set(this.formData, key, '');
    },
    verify() {
      // 手机号校验
      this.$refs['ruleForm'].validateField('mobile');
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./forgotPassWord.scss"
</style>