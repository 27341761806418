<template>
  <el-form-item label="" :prop="propName" ref="mobile" class="mobile">
    <el-input v-model="value" :placeholder="placeholderTxt" @blur="upDataValue">
      <div slot="prefix" class="input-icon-l pic" v-if="leftIcon">
        <img src="@/assets/img/icon_001.png" alt="" class="i">
      </div>
      <div slot="suffix" class="input-icon-r pic" @click="closeBtn" v-if="rightIcon && value">
        <img src="@/assets/img/icon_002.png" alt="" class="i">
      </div>
    </el-input>
  </el-form-item>
</template>

<script>
// import { EventBus } from '@/utils/EventBus'
export default {
  props: {
    mobile: {
      type: String,
      default: ''
    },
    propName: {
      type: String,
      default: ''
    },
    leftIcon: {
      type: Boolean,
      default: false
    },
    rightIcon: {
      type: Boolean,
      default: false
    },
    placeholderTxt: {
      type: String,
      default: '输入手机号'
    }
  },
  data() {
    return {
      value: this.mobile
    }
  },
  created(){
    // EventBus.$on('verify', this.verify);
  },
  methods: {
    // 清空
    closeBtn(key){
      this.value = '';
      // this.$refs.mobile.$el.getElementsByTagName('input')[0].focus();
      this.$emit('update:mobile', this.value);
    },
    upDataValue(){
      this.$emit('update:mobile', this.value);
    },
    verify(){

    },
  }
}
</script>

<style lang="scss" scoped>
  .mobile{
    margin-bottom: 0;
    ::v-deep .el-input__suffix{ display: none; }
    &:hover{
      ::v-deep .el-input__suffix{ display: flex; }
    }
    .input-icon-l{ width: 16px; }
    .input-icon-r{ width: 22px; }

    // 图标垂直居中
    ::v-deep .el-input__prefix{ display: flex; align-items: center; left: 18px; }
    ::v-deep .el-input--prefix .el-input__inner{ padding-left: 52px; }
    ::v-deep .el-input__suffix{ display: flex; align-items: center; right: 18px; cursor: pointer; }
    ::v-deep .el-input--suffix .el-input__inner{ padding-right: 52px; }

    ::v-deep .el-input__inner{
      height: 58px; line-height: 58px;
      border-radius: 14px; border: 1px solid #E5E5E5;
      font-size: 18px; color: #1B1B1B;
      &:focus{
        border-color: #FF6315;
      }
      &::-webkit-input-placeholder{
        color:#E5E5E5;
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color:#E5E5E5;
      }
      &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color:#E5E5E5;
      }
      &:-ms-input-placeholder{  /* Internet Explorer 10-11 */
        color:#E5E5E5;
      }
    }
  }
</style>