<template>
  <el-form-item label="" :prop="propName" ref="verificationCode1" class="verificationCode">
    <el-input v-model="value" placeholder="输入验证码" @blur="upDataValue">
      <div slot="prefix" class="input-icon-l pic" v-if="leftIcon">
        <img src="@/assets/img/icon_003.png" alt="" class="i">
      </div>
      <div slot="suffix" class="code-btn-txt" @click="btnCountDown">
        <div :class="{['placeholder-color']: countDownTxt !== '发送验证码'}">{{ countDownTxt }}</div>
      </div>
    </el-input>
  </el-form-item>
</template>

<script>
import { sendSmsCode } from "@/api/user"; // 获取验证码
export default {
  props: {
    verificationCode: {
      type: String,
      default: ''
    },
    propName: {
      type: String,
      default: ''
    },
    leftIcon: {
      type: Boolean,
      default: false
    },
    mobile: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      value: this.verificationCode,
      countDownTxt: '发送验证码',
    }
  },
  methods: {
    upDataValue(){
      this.$emit('update:verificationCode', this.value);
    },
    // 倒计时
    btnCountDown(){
      if(this.countDownTxt !== '发送验证码') return;

      var reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;    //11位手机号码正则
      if(this.mobile && reg_tel.test(this.mobile)){
        sendSmsCode({mobile: this.mobile});
        // 出现 60 不出现 0
        let count = 60;
        let txt = 's';
        let timer = 0;
        clearInterval(timer);
        this.countDownTxt = count + txt;
        timer = setInterval(() => {
          if(count > 1){
            count --;
            this.countDownTxt = count + txt;
          } else {
            clearInterval(timer);
            this.countDownTxt = '发送验证码';
          }
        }, 1000);
      } else {
        this.$emit('verify'); // 对表单的某个输入框进行校验
      }
    },
  }
}
</script>

<style lang="scss" scoped>
  .verificationCode{
    margin-bottom: 0;
    .input-icon-l{ width: 20px; }
    .code-btn-txt{ font-size: 16px; color: #FF6315; line-height: 22px; }
    
    // 图标垂直居中
    ::v-deep .el-input__prefix{ display: flex; align-items: center; left: 18px; }
    ::v-deep .el-input--prefix .el-input__inner{ padding-left: 52px; }
    ::v-deep .el-input__suffix{ display: flex; align-items: center; right: 18px; cursor: pointer; }
    ::v-deep .el-input--suffix .el-input__inner{ padding-right: 110px; } 

    ::v-deep .el-input__inner{
      height: 58px; line-height: 58px;
      border-radius: 14px; border: 1px solid #E5E5E5;
      font-size: 18px; color: #1B1B1B;
      &:focus{
        border-color: #FF6315;
      }
      &::-webkit-input-placeholder{
        color:#E5E5E5;
      }
      &::-moz-placeholder{   /* Mozilla Firefox 19+ */
        color:#E5E5E5;
      }
      &:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
        color:#E5E5E5;
      }
      &:-ms-input-placeholder{  /* Internet Explorer 10-11 */
        color:#E5E5E5;
      }
    }
  }
</style>