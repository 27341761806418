<template>
  <div class="app-container">
    <div class="x-wrap clearfix">
      <!-- 背景圆 -->
      <div class="bg-pic yuan"></div>
      <!-- 背景小方块 -->
      <div class="bg-pic fang pic">
        <img src="@/assets/img/pic_001.png" alt="" class="i">
      </div>
      <!--  -->
      <component :is='componentName[LoginPage]'/>
    </div>
  </div>
</template>

<script>
import login from "./login"
import forgotPassWord from "./forgotPassWord"
import { mapGetters } from "vuex";
export default {
  name: 'Login',
  components: {
    login, forgotPassWord
  },
  computed: {
    ...mapGetters([
      "LoginPage"
    ]),
  },
  data () {
    return {
      componentName: ['login', 'forgotPassWord']
    }
  },
}
</script>

<style lang="scss" scoped>
@import "./index.scss"
</style>
